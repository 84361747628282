import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { up } from 'styled-breakpoints'

function Title({ children, level, size }) {
  return (
    <StyledTitle as={`h${level}`} size={size}>
      {children}
    </StyledTitle>
  )
}

const StyledTitleM = css`
  font-size: 30px;
  line-height: 37px;
  padding-bottom: 20px;

  ${up('lg')} {
    font-size: 36px;
    line-height: 44px;
    padding-bottom: 28px;
  }

  ${up('xxxl')} {
    font-size: 48px;
    line-height: 120%;
    padding-bottom: 32px;
  }
`
const StyledTitleS = css``

export const StyledTitle = styled.h1`
  position: relative;
  font-family: ${(props) => props.theme.fontFamilySecondary};
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 0;
  color: ${(props) => props.theme.colorPrimary};

  &::after {
    position: absolute;
    content: '';
    display: block;
    width: 82px;
    height: 4px;
    left: 0;
    bottom: 0;
    background: ${(props) => props.theme.colorSecondary};
  }

  ${(props) => props.size === 'm' && StyledTitleM}
  ${(props) => props.size === 's' && StyledTitleS}
`

Title.defaultProps = {
  level: 2,
  size: 'm',
}

Title.propTypes = {
  children: PropTypes.node.isRequired,
  level: PropTypes.number,
  size: PropTypes.string,
}

export default Title
