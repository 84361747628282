import PropTypes from 'prop-types'
import styled from 'styled-components'
import parse from 'html-react-parser'
import { up } from 'styled-breakpoints'
import React from 'react'

function WysiwygOutput({ content, children }) {
  const options = {
    // eslint-disable-next-line consistent-return
    replace: (domNode) => {
      if (domNode.name === 'br') {
        return <></>
      }
    },
  }
  const parsedContent = parse(content, options)
  return <StyledArticle>{children || parsedContent}</StyledArticle>
}

WysiwygOutput.defaultProps = {
  content: '',
  children: undefined,
}

WysiwygOutput.propTypes = {
  content: PropTypes.string,
  children: PropTypes.node,
}

export const StyledArticle = styled.article`
  font-size: 14px;
  line-height: 18px;
  color: ${(props) => props.theme.textColor};
  font-weight: 400;

  ${up('lg')} {
    font-size: 14px;
    line-height: 18px;
  }

  ${up('xxxl')} {
    font-size: 14px;
    line-height: 18px;
  }

  a {
    color: ${(props) => props.theme.colorSecondary};
    background-color: transparent;
    transition: all ${(props) => props.theme.transitionTime};
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  strong {
    color: ${(props) => props.theme.colorPrimary};
    font-weight: 700;
  }

  img {
    width: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: ${(props) => props.theme.fontFamilySecondary};
    font-weight: 700;
    line-height: 120%;
    color: ${(props) => props.theme.colorPrimary};
    text-transform: uppercase;

    &:first-child {
      margin-top: 0;
    }

    ${up('md')} {
      line-height: 140%;
    }
  }

  h2 {
    position: relative;
    font-size: 30px;
    line-height: 37px;
    margin-bottom: 20px;
    padding-bottom: 17px;

    ${up('lg')} {
      font-size: 36px;
      line-height: 44px;
    }

    ${up('xl')} {
      font-size: 48px;
      line-height: 120%;
      margin-top: 40px;
      margin-bottom: 28px;
      padding-bottom: 20px;
    }

    &::after {
      position: absolute;
      content: '';
      display: block;
      width: 82px;
      height: 4px;
      left: 0;
      bottom: 0;
      background: ${(props) => props.theme.colorSecondary};
    }
  }

  h3 {
    position: relative;
    font-size: 22px;
    line-height: 130%;
    margin-top: 32px;
    margin-bottom: 20px;
    padding-bottom: 17px;

    ${up('md')} {
      font-size: 24px;
      line-height: 130%;
    }

    ${up('lg')} {
      font-size: 26px;
      line-height: 130%;
    }

    ${up('xl')} {
      font-size: 36px;
      line-height: 120%;
      margin-top: 40px;
      margin-bottom: 28px;
      padding-bottom: 20px;
    }

    &::after {
      position: absolute;
      content: '';
      display: block;
      width: 82px;
      height: 4px;
      left: 0;
      bottom: 0;
      background: ${(props) => props.theme.colorSecondary};
    }
  }

  h4,
  h5,
  h6 {
    position: relative;
    font-size: 22px;
    line-height: 130%;
    margin-top: 32px;
    margin-bottom: 20px;

    font-size: 16px;
    line-height: 130%;

    ${up('md')} {
      font-size: 16px;
      line-height: 130%;
    }

    ${up('lg')} {
      font-size: 20px;
      line-height: 130%;
    }

    ${up('xl')} {
      margin-top: 40px;
      margin-bottom: 28px;
      padding-bottom: 20px;
      font-size: 24px;
      line-height: 130%;
    }
  }

  p,
  ul,
  ol,
  dl,
  table,
  blockquote,
  pre,
  address,
  figure {
    margin-top: 0;
    margin-bottom: 25px;

    > * {
      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  ol {
    padding-left: 30px;

    ${up('lg')} {
      padding-left: 35px;
    }
    ${up('xl')} {
      padding-left: 45px;
    }
  }

  ul {
    padding-left: 0;
    list-style: none;

    li {
      position: relative;
      padding-left: 20px;

      ${up('lg')} {
        padding-left: 25px;
      }

      ${up('xl')} {
        padding-left: 30px;
      }

      &::before {
        position: absolute;
        left: 0;
        top: 0;
        content: '–';
        display: block;

        ${up('md')} {
        }
      }
    }
  }

  > * {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  p {
    &:empty {
      display: none;
    }
  }
`

export default WysiwygOutput
